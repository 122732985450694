import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import IconHPV2 from "../assets/image/icons/FAQ_HPV-02.png";

const Hpvinfo = () => {
  const { t } = useTranslation();
  return (
    <div className="faq_view_details opt1">
      <div className="faq_heading">
        <div className="faq_item_block">
          <div className="faq_item_block_pic">
            <img src={IconHPV2} alt="hpv" />
          </div>
          <div className="faq_item_info">
            <h3>{t("HPV")}</h3>
            <p>({t("Human Papilloma virus")})</p>
          </div>
        </div>
      </div>
      <div className="faq_des_block text-left">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t(
                "What is the difference between Pap smear, ThinPrep, and HPV screening?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "A pap smear or pap test looks for cell changes on a glass slide. In the ThinPrep pap test, cell samples are collected by a special brush then filters out the cells before depositing them in a thin monolayer on a glass slide. On the other hand, HPV screening it to look for the virus, Human papillomavirus) that causes cervical cancer."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t(
                "Are the results as accurate as going to hospitals or clinics?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "The accuracy of a test depends on collection performance. However, we provide simple-to-follow steps to make sure you’re on the right track!"
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {t("What are the symptoms of HPV infections?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Most people who contract HPV do not show any symptoms so it’s important to get HPV primary screening once you have experienced intercourse. Primary screening can detect HPV types and looks at how High-Risk types (16 and 18) can develop into something more concerning such as cervical cancer."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>{t("How is HPV transmitted?")}</Accordion.Header>
            <Accordion.Body>
              {t(
                "HPV is transmitted through sexual intercourse. It can be from male to female or vice versa. That’s why everyone should take HPV primary screening."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {t("Does HPV lead to cervical cancer?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "There are over 100 types of HPV, and not all of them lead to cancer. In fact, there are only 2 main HPV types that lead to cancer which are 16 and 18."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              {t("Is cervical cancer from genetics?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Cervical cancer does not come from genetics, it is considered a sexually transmitted disease. However, if members of your family tree have had cancer, your body might be more inclined to cancer, thus making it easier for HPV (if you’ve contracted it) to become cancer."
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              {t("If I test positive for HPV, do I have cancer?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "No, you don’t. HPV is not cancer. HPV is only the infection that has the potential to lead to cancer. Thus, it depends on which type of HPV you contracted and if your body can eliminate the virus. It can take up to 10 years for HPV to develop into cancer, and you can do so much to prevent that with the right healthcare."
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Hpvinfo;
