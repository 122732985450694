import { useAuth } from "../contexts/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

const IsNotAuthenticated = () => {
  const { isSignedIn } = useAuth();

  return !isSignedIn ? <Outlet /> : <Navigate to="/" />;
};

export default IsNotAuthenticated;
