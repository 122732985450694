import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";
import Button from "../Common/Button";
import logo from "../assets/image/home-icon.png";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { signin } = useAuth();

  return (
    <>
      <div
        style={{
          zoom: "120%",
        }}
        className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="block h-12 w-auto mx-auto" src={logo} alt="Logo" />
          <h4 className="mt-10 text-center font-bold leading-9 tracking-tight text-gray-900">
            Sign in
          </h4>
          <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
            This is an admin signin
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              signin?.mutate({ email, password });
            }}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm text-left font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border border-gray-300"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 border border-gray-300"
                />
              </div>
            </div>

            <div>
              <Button
                loading={signin?.isLoading}
                className="!w-full"
                type="submit"
              >
                Sign in
              </Button>
            </div>
          </form>

          <p className="flex flex-col gap-2 mt-10 text-center text-sm text-gray-500">
            Don't have an account?
            <Link
              to="/contact"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Contact Admins
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
