import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { classNames } from "../utils";

const AboutHeathSpan = () => {
  const { t, i18n } = useTranslation();
  const [selectValue, setSelectValue] = useState("significance");
  return (
    <div className="aboutContaianer">
      <div className="container p-md-4">
        <h2
          className={classNames(
            "ml-6 flex items-center gap-2",
            i18n.language === "th"
              ? "flex-row-reverse justify-end max-md:justify-center"
              : "max-md:justify-center"
          )}
        >
          <span>{t("what_is")}</span>{" "}
          <span className="heathspan">HealthSpan</span>
        </h2>
        <div className="row contentHeathSpan ps-md-4">
          <div className="col-md-4 ">
            <div className="">
              <div className="contentHeath_block_left">
                <ul>
                  <li
                    className={selectValue === "significance" ? "overview" : ""}
                    onClick={() => setSelectValue("significance")}
                  >
                    {t("significance")}
                  </li>
                  <li
                    className={selectValue === "vision" ? "overview" : ""}
                    onClick={() => setSelectValue("vision")}
                  >
                    {t("vision")}
                  </li>
                  <li
                    className={selectValue === "mission" ? "overview" : ""}
                    onClick={() => setSelectValue("mission")}
                  >
                    {t("mission")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-8 custom-padding-left">
            <div className="contentHeath_block_right">
              <h4 className="paragraphtext">
                {t(`${selectValue}_description`)}
              </h4>
              <div className="seemorecontent pt-4">
                <Link to="/aboutus">
                  <button className="seemore">{t("see_more")}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeathSpan;
