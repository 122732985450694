import { toast } from "react-toastify";
import { API_URL } from "../apis";

export default async function request(url, options, parse = "json") {
  const response = await fetch(url, options);
  const data = parse === "json" ? await response.json() : await response.text();

  if (!response.ok) {
    if (response.status === 401) {
      const refreshResponse = await refresh();
      if (!refreshResponse.ok) {
        if (refreshResponse.status === 401) {
          toast.error("Session Expired");
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          window.location.href = "/signin";
        }

        throw new Error(data.message);
      }

      const refreshData = await refreshResponse.json();
      localStorage.setItem("access", refreshData.jwtToken);
      localStorage.setItem("refresh", refreshData.refreshToken);

      return await request(url, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
      });
    }

    throw new Error(data.message);
  }

  return data;
}

export const refresh = async () => {
  return await fetch(`${API_URL}/authapi/auths/refresh-token`, {
    method: "PUT",
    body: JSON.stringify({
      refreshToken: localStorage.getItem("refresh"),
    }),
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
};
