import old from "./old.json";
import common from "./common.json";
import home from "./home.json";
import product from "./product.json";
import aboutus from "./aboutus.json";
import pedsupab from "./pedsupab.json";
import blog from "./blog.json";

export default {
  ...old,
  ...common,
  ...home,
  ...product,
  ...aboutus,
  ...pedsupab,
  ...blog,
};
