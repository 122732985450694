import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import v_sample_1 from '../assets/image/vagina-specm-1.png';
import v_sample_2 from '../assets/image/vagina-specm-2.png';
import v_sample_3 from '../assets/image/vagina-specm-3.png';
import v_sample_4 from '../assets/image/vagina-specm-4.png';
import v_sample_5 from '../assets/image/vagina-specm-5.png';
import v_sample_6 from '../assets/image/vagina-specm-6.png';
import pn_sample_1 from '../assets/image/penile-specm-1.png';
import pn_sample_2 from '../assets/image/penile-specm-2.png';
import pn_sample_3 from '../assets/image/penile-specm-3.png';
import pn_sample_4 from '../assets/image/penile-specm-4.png';
import pn_sample_5 from '../assets/image/penile-specm-5.png';
import pn_sample_6 from '../assets/image/penile-specm-6.png';
import rectal_sample_1 from '../assets/image/rectal-specm-1.png';
import rectal_sample_2 from '../assets/image/rectal-specm-2.png';
import rectal_sample_3 from '../assets/image/rectal-specm-3.png';
import rectal_sample_4 from '../assets/image/rectal-specm-4.png';
import rectal_sample_5 from '../assets/image/rectal-specm-5.png';
import rectal_sample_6 from '../assets/image/rectal-specm-6.png';
import throat_sample_1 from '../assets/image/throat-specm-1.png';
import throat_sample_2 from '../assets/image/throat-specm-2.png';
import throat_sample_3 from '../assets/image/throat-specm-3.png';
import throat_sample_4 from '../assets/image/throat-specm-4.png';
import throat_sample_5 from '../assets/image/throat-specm-5.png';
import throat_sample_6 from '../assets/image/throat-specm-6.png';
import { t } from "i18next";

const CollectSpecimen = () => {
  const allData = [
    {
      title: t("vagina"),
      innerData: [
        { id: 1, imgage: v_sample_1, description: t("vagina_step_1") },
        { id: 2, imgage: v_sample_2, description: t("vagina_step_2") },
        { id: 3, imgage: v_sample_3, description: t("vagina_step_3") },
        { id: 4, imgage: v_sample_4, description: t("vagina_step_4") },
        { id: 5, imgage: v_sample_5, description: t("vagina_step_5") },
        { id: 6, imgage: v_sample_6, description: t("vagina_step_6") },
      ],
    },
    {
      title: t("penile"),
      innerData: [
        { id: 1, imgage: pn_sample_1, description: t("penile_step_1") },
        { id: 2, imgage: pn_sample_2, description: t("penile_step_2") },
        { id: 3, imgage: pn_sample_3, description: t("penile_step_3") },
        { id: 4, imgage: pn_sample_4, description: t("penile_step_3") },
        { id: 5, imgage: pn_sample_5, description: t("penile_step_5") },
        { id: 6, imgage: pn_sample_6, description: t("penile_step_6") },
      ],
    },
    {
      title: t("rectal"),
      innerData: [
        {
          id: 1,
          imgage: rectal_sample_1,
          description: t("rectal_step_1"),
        },
        {
          id: 2,
          imgage: rectal_sample_2,
          description: t("rectal_step_2"),
        },
        {
          id: 3,
          imgage: rectal_sample_3,
          description: t("rectal_step_3"),
        },
        {
          id: 4,
          imgage: rectal_sample_4,
          description: t("rectal_step_4"),
        },
        {
          id: 5,
          imgage: rectal_sample_5,
          description: t("rectal_step_5"),
        },
        {
          id: 6,
          imgage: rectal_sample_6,
          description: t("rectal_step_6"),
        },
      ],
    },
    {
      title: t("throat"),
      innerData: [
        {
          id: 1,
          imgage: throat_sample_1,
          description: t("throat_step_1"),
        },
        {
          id: 2,
          imgage: throat_sample_2,
          description: t("throat_step_2"),
        },
        {
          id: 3,
          imgage: throat_sample_3,
          description: t("throat_step_3"),
        },
        {
          id: 4,
          imgage: throat_sample_4,
          description: t("throat_step_4"),
        },
        {
          id: 5,
          imgage: throat_sample_5,
          description: t("throat_step_5"),
        },
        {
          id: 6,
          imgage: throat_sample_6,
          description: t("throat_step_6"),
        },
      ],
    },
  ];

  return (
    <section className="specimen-wrap">
      <h2 className="text-center">{t("how_to_collect_specimen")}</h2>
      <div className="container">
        <OwlCarousel
          className="owl-theme"
          items={1}
          nav={true}
          dots={false}
          smartSpeed={0}
          loop={true}
        >
          {allData.map((data, index) => (
            <div className="item" key={index}>
              <div className="speciment-blk">
                <h3 className="!text-center">{data.title}</h3>
                <Row>
                  {data.innerData
                    .filter((newData) => newData)
                    .map((newData, index) => (
                      <Col lg={4} md={6} xs={12} sm={12} key={index}>
                        <Card className="specm-card">
                          <div className="circle-img-wrp">
                            <Card.Subtitle as="span">
                              {newData.id}
                            </Card.Subtitle>
                            <div className="img-blk">
                              <img src={newData.imgage} alt="v_sample_1" />
                            </div>
                          </div>
                          <CardBody>
                            <Card.Text>{newData.description}</Card.Text>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default CollectSpecimen