import React, { useState, useEffect } from "react";
import logo from "../../src/assets/image/kn-logo.png";
import { Navbar, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [currentpage, setCurrentPage] = useState();
  const navigate = useNavigate();

  const changeLanguage = (value) => {
    navigate("/" + value + location.pathname.replace("/" + i18n.language, ""));
    i18n.changeLanguage(value);
  };
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPageName = pathSegments[pathSegments.length - 1];
    setCurrentPage(currentPageName);
  }, [location.pathname]);

  return (
    <>
      <header className="hdr-wrapper">
        <Navbar expand="lg" activeKey="/home" className="nav m-0 p-0">
          <div className="!w-full flex justify-between items-center px-2 overflow-hidden">
            <div className="max-sm:self-baseline max-sm:pt-2">
              <Navbar.Brand href="/pedsupab" className="block">
                <img src={logo} alt="logo" className="w-20" />
              </Navbar.Brand>
            </div>
            <div className="flex items-center max-lg:hidden">
              <Nav as="ul">
                <Nav.Item as="li">
                  <Nav.Link
                    href="/pedsupab/blog/category/sex-counsel"
                    active={currentpage === "sexcounsel"}
                  >
                    {t("Sex Counsel")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="/pedsupab/blog/category/pillow-talk"
                    active={currentpage === "pillowtalk"}
                  >
                    {t("Pillow Talk")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    active={currentpage === "sexualcare"}
                    href="/sexualcare"
                  >
                    {t("Sexual Care")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/video">{t("Video")}</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-x-4 justify-center sm:justify-end max-sm:w-full max-lg:pt-2">
              {/* <div className="gap-x-2 flex items-center justify-end w-full">
                <Nav.Item>
                  <Form.Control
                    type="text"
                    placeholder={t("search")}
                    className="searchBar m-0 flex-1"
                  />
                </Nav.Item>
              </div> */}
              <div className="flex items-center justify-end gap-x-2 w-full sm:w-fit">
                <Nav.Item>
                  <select
                    className="langDrop btn nav-link mx-0 px-0"
                    onChange={(e) => changeLanguage(e.target.value)}
                    value={i18n.language}
                  >
                    {i18n.languages.map((lang) => (
                      <option value={lang} selected={lang === i18n.language}>
                        {lang.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </Nav.Item>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  style={{
                    backgroundImage: `background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgb(255, 0, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")`,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="lg:!hidden !w-full">
            <Navbar.Collapse id="basic-navbar-nav">
              <div as="ul" className="!w-full flex flex-col items-center gap-1">
                <Nav.Item as="li">
                  <Nav.Link
                    href="/pedsupab/blog/category/sex-counsel"
                    active={currentpage === "sexcounsel"}
                  >
                    {t("Sex Counsel")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="/pedsupab/blog/category/pillow-talk"
                    active={currentpage === "pillowtalk"}
                  >
                    {t("Pillow Talk")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    active={currentpage === "sexualcare"}
                    href="/sexualcare"
                  >
                    {t("Sexual Care")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/video">{t("Video")}</Nav.Link>
                </Nav.Item>
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
