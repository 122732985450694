import { API_URL } from ".";
import request from "../utils/request";

export const signin = async (data) => {
  return await request(`${API_URL}/authapi/auths/login`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const signout = async () => {
  return await request(`${API_URL}/authapi/auths/logout`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access"),
    },
  });
};

export const getUserInfo = async () => {
  return await new Promise((resolve, reject) => {
    if (localStorage.getItem("access")) {
      resolve({});
    } else {
      reject({});
    }
  });
};
