import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locals/en";
import th from "./locals/th";

const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ["en", "th"],
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: ["en", "th"],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18n;
