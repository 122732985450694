import React from "react";
import Layout from "../Layout";
import "./aboutus.css";
import { useTranslation } from "react-i18next";
import icon1 from "../assets/image/about-item-bg-1.svg";

const Aboutus = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="inner_banner">
        <div className="inner_slider">
          <div className="inner_slider_panel">
            <div className="container customContainer">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading_info">
                    <p className="text-center !capitalize text-[24px]">
                      {t("What is")} <span>Healthspan</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overview_info" id="id_overview">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_info text-center">
                <h1 className="text-center">{t("Overview")}</h1>
                <p>{t("aboutus__overview_1")}</p>
                <p>{t("aboutus__overview_2")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mission-vision">
        <div className="container flex flex-col items-center justify-center h-full gap-4">
          <div className="row text-center">
            <div className="vision">
              <h1>{t("Vision")}</h1>
              <p>{t("aboutus__vision")}</p>
            </div>
          </div>
          <p className="w-[100px] border-b-2 border-[#61422C]"></p>
          <div className="row text-center">
            <div className="mission">
              <h1>{t("Mission")}</h1>
              <p>{t("aboutus__mission")}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Aboutus;
