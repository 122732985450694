import React from "react";
import Layout from "../Layout";
import "../Affiliates/affiliates.css";
import affiliatesadd from "../../src/assets/image/updatedicon/affiliates_add.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AFFILIATE_API_BASE_URL } from "../env";

const Affiliates = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      email: "",
      confirm_email: "",
      links: [""],
      question: "",
    },
    onSubmit: (values) => {
      values.socialMediaLinks = values.links.toString();
      const lang = localStorage.getItem("language");
      axios({
        method: "POST",
        url: `${AFFILIATE_API_BASE_URL}/affiliates/register?lang=${lang}`,
        data: values,
      })
        .then(function (res) {
          console.log(res);
          if (res.data.status === 400) {
            toast.error(res.data.message);
          } else {
            toast.success("Your application has been submitted.");
            formik.resetForm();
            return;
          }
        })
        .catch(function (res) {
          console.log(res);
        });
    },
    validationSchema: yup.object().shape({
      FirstName: yup.string().required("First name is required"),
      LastName: yup.string().required("Last name is required"),
      email: yup.string().email().required("Email is required"),
      confirm_email: yup
        .string()
        .oneOf([yup.ref("email"), null], "Emails must match")
        .required("Confirm Email is required"),
      links: yup
        .array()
        .of(yup.string().url("Must be a valid URL").required("URL is required"))
        .required("URLs array is required")
        .min(1, "At least one URL is required"),
      question: yup.string(),
    }),
  });

  console.log("formik", formik.values, formik.errors);

  return (
    <Layout>
      <div className="container avaBg">
        <div className="row mt-4 pb-3">
          <div className="col-md-12">
            <div className="innerBreadcum">
              <span>{t("Home")}</span> <i class="fa-solid fa-angle-right"></i>{" "}
              {t("Affiliates")}
            </div>
          </div>
        </div>

        <div className="row mt-4 pb-5 shippingBase affilates_info">
          <div className="col-md-6">
            <div className="shippingadd">
              <img src={affiliatesadd} alt="" />
            </div>
          </div>
          <div className="col-md-6 text-start">
            <h1 className="pb-2 text-center">
              {t("Join Our Affiliate Programme")}
            </h1>
            <p className="adj_padding text-center">
              {t(
                "Become part of the movement to create a sexually healthy society"
              )}
            </p>
            <div>
              <span className="pb-1">{t("First Name")} </span>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="FirstName"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.FirstName && formik.errors.FirstName && (
                <div style={{ color: "red" }}>{formik.errors.FirstName}</div>
              )}
            </div>

            <div>
              <span className="pb-1">{t("Last Name")}</span>
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.LastName && formik.errors.LastName && (
                <div style={{ color: "red" }}>{formik.errors.LastName}</div>
              )}
            </div>

            <div>
              <span className="pb-1">{t("Email")}</span>
              <input
                type="email"
                className="form-control"
                placeholder=""
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ color: "red" }}>{formik.errors.email}</div>
              )}
            </div>

            <div>
              <span className="pb-1">{t("Confirm Email")}</span>
              <input
                type="email"
                className="form-control"
                placeholder=""
                name="confirm_email"
                value={formik.values.confirm_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.confirm_email && formik.errors.confirm_email && (
                <div style={{ color: "red" }}>
                  {formik.errors.confirm_email}
                </div>
              )}
            </div>
            <div>
              <div className="linkadd_opt">
                <span className="pb-1">{t("Your Social Media Links")} </span>

                <div>
                  {formik.values.links.map((_, index) => (
                    <div className="mb-2">
                      <div key={index} className="dynamic-div">
                        <input
                          type="text"
                          className="form-control !mb-0 !pb-0"
                          name={`links.${index}`}
                          value={formik.values.links[index]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t("Enter link here...")}
                        />
                        <div
                          className="linkadd_text_custom"
                          onClick={() => {
                            const newLinks = formik.values.links.filter(
                              (_, i) => i !== index
                            );
                            formik.setFieldValue("links", newLinks);
                          }}
                        >
                          <em>-</em>
                        </div>
                      </div>
                      {formik.touched.links && formik.errors.links?.[index] && (
                        <div style={{ color: "red" }}>
                          {formik.errors.links?.[index]}
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className="linkadd_text"
                    onClick={() =>
                      formik.setFieldValue("links", [
                        ...formik.values.links,
                        "",
                      ])
                    }
                  >
                    <div className="form-control !flex !items-center !justify-between !cursor-pointer">
                      <span>{t("Add Link")}</span>
                      <em>+</em>
                    </div>
                  </div>
                </div>
              </div>
              {formik.touched.links &&
                formik.errors.links &&
                !Array.isArray(formik.errors.links) && (
                  <div style={{ color: "red" }}>{formik.errors.links}</div>
                )}
            </div>

            <div className="mb-3">
              <span className="pb-1">
                {t("Why do you want to join our programme")}
              </span>
              <textarea
                class="form-control mb-0 textareainfo"
                rows="3"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              <div className="word_count">
                <em>{t("Limit")} 150 </em>
              </div>
            </div>

            <div className="mycartsubmit def_btnwidth">
              <button class="commonButton" onClick={formik.handleSubmit}>
                {t("JOIN")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Affiliates;
