import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import img1 from "../assets/image/img1.png";
import healthspanStore from "../assets/image/store1.png";
import shopeeStore from "../assets/image/store2.png";
import lazadaStore from "../assets/image/store3.png";
import otherproduct from "../assets/image/otherproduct.png";
import perifitImage from "../assets/image/perifit.png";
import heartIcon from "../assets/image/hearticon.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCart } from "../contexts/CartContext";
import { classNames } from "../utils";

const Index = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addProductToCart } = useCart();
  const search = useLocation().search;
  const data = new URLSearchParams(search).get("product");
  const [count, setCount] = useState(1);
  const [name, setName] = useState("xxxxxxxxxx");
  // const [toggle, setToggle] = useState(false);
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState(
    "xxxxxx xxxxx xxxxxx xxxxxx xxxx xxx xxxxxx xxxxxx xxxxxx xxxxxxx xxxx xxxxx xxxxxx xxxxxx"
  );

  useEffect(() => {
    if (['hpv', 'stis', 'perifit'].includes(data)) {
      setProductName(data);
    }
  }, [data]);

  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    setCount(count - 1);
    if (count === 1) {
      setCount(1);
    }
  };

  const options = {
    loop: false,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  const handleSubmit = () => {
    toast.success("Succesfully submitted");
  };

  return (
    <Layout>
      <div className="container mx-6">
        <div className="row mt-4 pb-3">
          <div className="flex">
            <p>
              <button
                className="commonButton"
                onClick={() => navigate("/product")}
              >
                <i class="fa-regular fa-arrow-left-long"></i> {t("BACK")}
              </button>
            </p>
          </div>
        </div>

        <div className="row mb-5" id="id_hpv">
          <div className="col-md-7 flowImg">
            {productName === "hpv" ? (
              <img src={img1} alt="HPV Kits" />
            ) : productName === "stis" ? (
              <img src={otherproduct} alt="SITs Kits" />
            ) : productName === "perifit" ? (
              <img src={perifitImage} alt="Perifit Kits" />
            ) : (
              <div>No product selected</div>
            )}
          </div>
          <div className="col-md-5 max-md:!mt-4">
            {productName === "hpv" ? (
              <>
                <h2 className="text-[#6FBBA0] text-left">{t("HPV Kits")}</h2>
                <p className="text-[#6FBBA0] text-left">
                  <small>{t("self-collecting Kit")}</small>
                </p>
                <div className="bdr"></div>
                <div className="revw">
                  <h4>
                    {new Intl.NumberFormat("th-TH", {
                      style: "currency",
                      currency: "THB",
                      maximumFractionDigits: 0,
                    }).format(1600)}
                  </h4>
                  <span>{t("Review 0")}</span>
                </div>
              </>
            ) : productName === "stis" ? (
              <>
                <h2 className="text-[#57ADD7] text-left">{t("SITs Kits")}</h2>
                <p className="text-[#57ADD7] text-left">
                  <small>{t("self-collecting Kit")}</small>
                </p>
                <div className="bdr"></div>
                <div className="revw">
                  <h4>
                    {new Intl.NumberFormat("th-TH", {
                      style: "currency",
                      currency: "THB",
                      maximumFractionDigits: 0,
                    }).format(2650)}
                  </h4>
                  <span>{t("Review 0")}</span>
                </div>
              </>
            ) : productName === "perifit" ? (
              <>
                <h2 className="text-[#6FBBA0] text-left">{t("Perifit")}</h2>
                <p className="text-[#6FBBA0] text-left">
                  <small>{t("Vaginal & Pelvic Floor tightening Device")}</small>
                </p>
                <div className="bdr"></div>
                <div className="revw">
                  <h4>
                    {new Intl.NumberFormat("th-TH", {
                      style: "currency",
                      currency: "THB",
                      maximumFractionDigits: 0,
                    }).format(5900)}
                  </h4>
                  <span>{t("Review 0")}</span>
                </div>
              </>
            ) : (
              <div>No product selected</div>
            )}

            <div className="commonGrey my-4">
              <ul class="nav nav-pills infoTab" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    {t("Information")}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    {["hpv", "stis"].includes(productName)
                      ? t("Before Getting Tested")
                      : t("benefits")}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    {["hpv", "stis"].includes(productName)
                      ? t("What If I Test Positive")
                      : t("how_to_use")}
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="tabContnt">
                    <p className="text-left">
                      {t(`product_information_${data}`)}
                    </p>

                    <p className="text-left">
                      {t(`product_information_${data}_footnote`)}
                    </p>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {["hpv", "stis"].includes(productName) ? (
                    <div className="tabContnt">
                      <p className="text-left m-0">
                        {t(
                          "Refrain from collecting sample if you have engaged in sexual intercourse within the past 24 hours."
                        )}
                      </p>
                      <p className="text-left m-0">
                        {t(
                          "Avoid intentionally cleaning the collection area before performing the swab."
                        )}
                      </p>
                      <p className="text-left m-0">
                        {t(
                          "Do not use any conterception pill preferbly 24 - 36 hours before collecting sample"
                        )}
                      </p>
                      <p className="text-left m-0">
                        {t(
                          "Do not collect sample if have had physical examination within the past 24 hours"
                        )}
                      </p>
                      <p className="text-left m-0">
                        {t(
                          "Collect sample at least 5-7 days after period ends"
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="tabContnt">
                      <p className="text-left m-0">
                        {`✅ ${t("Increase pelvic floor strength")}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t(
                          "Prevents urinary incontinence while pushing"
                        )}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t("Prevents incontinence")}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t(
                          "Tightens the vagina for good sexual health"
                        )}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t("Helps mothers recover after childbirth")}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t("Corrects urinary incontinence")}`}
                      </p>
                      <p className="text-left m-0">
                        {`✅ ${t("Prevents uterine prolapse")}`}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  class="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {["hpv", "stis"].includes(productName) ? (
                    <div className="tabContnt">
                      <p className="text-left m-0">
                        {t(
                          "First of all, it's alright! We know testing positive can be daunting, however, now that you know, it's easier to get treated and motitor your symptoms."
                        )}
                      </p>
                      <p className="text-left m-0">
                        {t("What we can provide:")}
                      </p>
                      <p className="text-left m-0">
                        {t(
                          "Contact us and we will connect you to doctors, hospitals, or clinics closest to you. Let us know if you need mental support! You can also use our results to provide as information to hospitals and get treated for your infection."
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="tabContnt">
                      <p className="text-left m-0">
                        {`${t("How to connect and use Perifit")}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t("Download the Perifit Application")}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t("Turn on Bluetooth")}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "Connect Perifit (the light on the device is on)"
                        )}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "Wash your hands and prepare to put on the device"
                        )}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "Put the device in a lying position, slightly bend your knees. It is recommended to arrange your posture to make you feel most comfortable"
                        )}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "Start training, the program will be your trainer until the end"
                        )}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "After use, clean the device with mild soap and dry it before storing"
                        )}`}
                      </p>
                      <p className="text-left m-0 d-flex align-items-center">
                        <img src={heartIcon} alt="" className="me-2" />
                        {`${t(
                          "The box includes a cloth bag for storing the device for easy portability"
                        )}`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="qtty">
              {t("Quantity")}
              <div className="qtybtnbg">
                <button className="qtybtn1" onClick={decrementCount}>
                  -
                </button>
                <span className="countspan">{count}</span>
                <button className="qtybtn2" onClick={incrementCount}>
                  +
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-1.5">
              <button
                className="commonButton cartBtn"
                onClick={() => {
                  addProductToCart(data, count);
                  setCount(1);
                  toast.success("Succesfully added to cart");
                }}
              >
                {t("Add To Cart")}
              </button>
              <button
                className="commonButton buyBtn"
                onClick={() => {
                  addProductToCart(data, count);
                  navigate(`/cart`);
                }}
              >
                {t("Buy Now")}
              </button>
            </div>
          </div>
        </div>

        <div className="row text-left" id="id_how_is_working">
          <div className="col-md-12">
            <h3>
              {productName === "hpv"
                ? t("What is")
                : productName === "stis"
                ? t("What are")
                : productName === "perifit"
                ? t("Why should we use")
                : ""}{" "}
              <span
                className={classNames(
                  productName === "hpv"
                    ? "text-[#6FBBA0]"
                    : productName === "stis"
                    ? "text-[#57ADD7]"
                    : productName === "perifit"
                    ? "text-[#FF6347]"
                    : ""
                )}
              >
                {productName === "hpv"
                  ? t("HPV")
                  : productName === "stis"
                  ? t("STIs")
                  : productName === "perifit"
                  ? t("Perifit")
                  : ""}{" "}
                ?
              </span>
            </h3>
            <p>
              {productName === "hpv" ? (
                t(
                  "HPV, or Human Papillomavirus, is a sexually transmitted infection that infects the skin and mucous membranes of humans. Both males and females can contract HPV. It is the most common sexually transmitted infection worldwide, transmitted through vaginal, anal, or oral sex, as well as skin-to-skin contact. Prevention and early detection are key in managing HPV-related health risks. Vaccination against HPV is highly recommended for both males and females to help prevent HPV infection and associated diseases. Additionally, regular screenings and HPV testing can help detect any abnormalities or signs of infection, allowing for timely medical intervention."
                )
              ) : productName === "stis" ? (
                <>
                  {t("What is SITs-1")}
                  <br />
                  <br />
                  {t("What is SITs-2")}
                </>
              ) : productName === "perifit" ? (
                t("Perifit_use")
              ) : null}
            </p>

            <div className="commonGrey mt-4 p-4">
              <div className="commonHdnflex mb-3">
                <h4>{t("Where To Buy")}</h4>
              </div>
              <div className="buyPlace">
                <a
                  href="/"
                  className="shopFind flex items-center justify-center"
                >
                  <img src={healthspanStore} alt="" />
                </a>
                <a
                  href={
                    data === "hpv"
                      ? "https://shopee.co.th/AVA-HPV-Self-Collect-Kit-%E0%B9%80%E0%B8%AD%E0%B8%A7%E0%B8%B2-%E0%B8%8A%E0%B8%B8%E0%B8%94%E0%B9%80%E0%B8%81%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%AD%E0%B8%8A%E0%B8%9E%E0%B8%B5%E0%B8%A7%E0%B8%B5%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B9%80%E0%B8%AD%E0%B8%87-i.720175025.16478902404"
                      : "https://shopee.co.th/AVA-STIs-Self-Collect-Kit-%E0%B9%80%E0%B8%AD%E0%B8%A7%E0%B8%B2-%E0%B8%8A%E0%B8%B8%E0%B8%94%E0%B9%80%E0%B8%81%E0%B9%87%E0%B8%9A%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%82%E0%B8%A3%E0%B8%84%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%9E%E0%B8%A8%E0%B8%AA%E0%B8%B1%E0%B8%A1%E0%B8%9E%E0%B8%B1%E0%B8%99%E0%B8%98%E0%B9%8C%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B8%95%E0%B8%B1%E0%B8%A7"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="shopFind flex items-center justify-center"
                >
                  <img src={shopeeStore} alt="" />
                </a>
                <a
                  href={
                    data === "hpv"
                      ? "https://www.lazada.co.th/products/-i4200747046.html"
                      : "/"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="shopFind flex items-center justify-center"
                >
                  <img src={lazadaStore} alt="" />
                </a>
              </div>
            </div>

            <div
              className="commonGrey mt-4 p-4 mb-4"
              id="id_stis"
              style={{ display: "none" }}
            >
              <div className="commonHdnflex mb-3">
                <h4>{t("Reviews")}</h4>{" "}
                <span
                  className="writeRev"
                  data-bs-toggle="modal"
                  data-bs-target="#reviewModal"
                >
                  <i class="fa-solid fa-pen-line"></i> {t("Write a review")}
                </span>
              </div>
              <div className="reviewCarousel">
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <div className="reviewBlock">
                      <div className="revCircle"></div>
                      <div>
                        <h5>{name}</h5>
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="reviewBlock">
                      <div className="revCircle"></div>
                      <div>
                        <h5>xxxxxxxxxx</h5>
                        <p>
                          xxxxxx xxxxx xxxxxx xxxxxx xxxx xxx xxxxxx xxxxxx
                          xxxxxx xxxxxxx xxxx xxxxx xxxxxx xxxxxx
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="reviewBlock">
                      <div className="revCircle"></div>
                      <div>
                        <h5>xxxxxxxxxx</h5>
                        <p>
                          xxxxxx xxxxx xxxxxx xxxxxx xxxx xxx xxxxxx xxxxxx
                          xxxxxx xxxxxxx xxxx xxxxx xxxxxx xxxxxx
                        </p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-md-12 otherprd">
                <h4>{t("Other Product")}</h4>
                {data === "hpv" ? (
                  <div className="row">
                    <img
                      src={otherproduct}
                      alt="SITs Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=stis")}
                    />

                    <img
                      src={perifitImage}
                      alt="Perifit Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=perifit")}
                    />
                  </div>
                ) : data === "stis" ? (
                  <div className="row">
                    <img
                      src={img1}
                      alt="HPV Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=hpv")}
                    />
                    <img
                      src={perifitImage}
                      alt="Perifit Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=perifit")}
                    />
                  </div>
                ) : data === "perifit" ? (
                  <div className="row">
                    <img
                      src={img1}
                      alt="HPV Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=hpv")}
                    />
                    <img
                      src={otherproduct}
                      alt="SITs Kits"
                      className="!w-60 !h-40"
                      onClick={() => navigate("/ava?product=stis")}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Review-Modal */}

      <div
        class="modal fade reviewModal"
        id="reviewModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered text-center">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close modalClosebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-regular fa-xmark"></i>
            </button>
            <div class="modal-body">
              <h4 className="mb-4 mt-3">
                <i class="fa-solid fa-pen-line"></i> {t("Write a review")}
              </h4>
              <form>
                <h6>{t("Do you like the product?")}</h6>
                <input
                  type="text"
                  placeholder={t("Name")}
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
                <textarea
                  placeholder={t("Write your review here")}
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <p className="text-end">
                  <small>{t("Limit 150")}</small>
                </p>
                <div className="text-center mb-2">
                  <button
                    type="button"
                    class="commonButton"
                    onClick={handleSubmit}
                  >
                    {t("Submit Your Review")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
