import React, { useState } from "react";
import css from "../Faq/faq.css";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import IconSH6 from '../assets/image/icons/FAQ_Sexual-06.png'
const SexualHealth = () => {
  const { t } = useTranslation();
  return (
    <div className="faq_view_details opt1">
      <div className="faq_heading">
        <div className="faq_item_block">
          <div className="faq_item_block_pic">
            <img src={IconSH6} alt="SEXUAL" />
          </div>
          <div className="faq_item_info">
            <h3>{t("SEXUAL HEALTH")}</h3>
          </div>
        </div>
      </div>
      <div className="faq_des_block text-left">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t(
                "What are the long-term effects of hormonal birth control on sexual health?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Hormonal birth control, like the pill, IUDs, or implants, can have various long-term effects on sexual health. While they are effective in preventing pregnancy, they may cause changes in menstrual cycles, libido, and vaginal health. Some people experience decreased libido, while others may see an increase. Hormonal contraceptives can also affect the vaginal microbiome, potentially leading to increased susceptibility to infections. It's important to discuss these potential effects with a healthcare provider to find the most suitable contraceptive method."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t("How does mental health impact sexual health?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Mental health significantly impacts sexual health. Conditions like depression, anxiety, and stress can affect sexual desire, performance, and satisfaction. Mental health issues can lead to decreased libido, erectile dysfunction in men, and difficulty achieving orgasm in both men and women. Additionally, a negative body image or past traumatic experiences can also hinder sexual well-being. It's crucial to address mental health concerns with a professional to improve overall sexual health."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {t("Can lifestyle choices impact fertility?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Yes, lifestyle choices can significantly impact fertility in both men and women. Factors like smoking, excessive alcohol consumption, obesity, and poor nutrition can reduce fertility. Stress and lack of sleep also play a role. For men, heat exposure to the testicles and certain medications can affect sperm quality and count. For women, irregular menstrual cycles and hormonal imbalances influenced by lifestyle can affect ovulation. Adopting a healthy lifestyle, including a balanced diet, regular exercise, and avoiding harmful substances, can improve fertility."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {t(
                "How can I improve my sexual health and enhance sexual satisfaction?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Improving sexual health and enhancing sexual satisfaction involves several factors: open communication with your partner about desires and boundaries, maintaining overall health through diet and exercise, managing stress, and exploring preferences safely and consensually. It's also important to educate oneself about sexual health and seek professional advice if experiencing any difficulties."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {t(
                "How important is personal hygiene for sexual health, and what are the best practices?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Personal hygiene is crucial for maintaining sexual health. Good hygiene practices reduce the risk of infections and enhance comfort during sexual activities. It includes regular bathing, cleaning the genital area before and after sexual activity, and urinating after intercourse to reduce the risk of urinary tract infections. It's also important to wash hands and any sexual toys before and after use. Remember, maintaining personal hygiene is not only respectful to yourself but also to your partner."
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default SexualHealth;
