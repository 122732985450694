import React, { useState } from "react";
import Layout from "../Layout";
import css from "../Faq/faq.css";
import Hpvinfo from "../Faq/Hpvinfo";
import Stis from "../Faq/Stis";
import SexualHealth from "../Faq/SexualHealth";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import IconHPV from '../assets/image/icons/FAQ_HPV-01.png'
import IconSTIS from '../assets/image/icons/FAQ_STIs-03.png'
import IconSH from '../assets/image/icons/FAQ_Sexual-05.png'
import IconHPV2 from '../assets/image/icons/FAQ_HPV-02.png'
import IconSTIS4 from '../assets/image/icons/FAQ_STIs-04.png'
import IconSH6 from '../assets/image/icons/FAQ_Sexual-06.png'
const Faq = () => {
  const [isOpenopt, setIsOpenopt] = useState("HPV");
  const { t } = useTranslation();
  const toggle = (value) => {
    console.log("value", value);
    setIsOpenopt(value);
  };
  return (
    <Layout>
      <div className="container">
        <div className="row mt-4 pb-3">
          <div className="col-md-12">
            <div className="innerBreadcum">
              <span>{t("Home")}</span> <i class="fa-solid fa-angle-right"></i>{" "}
              {t("FAQ")}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {/* <div className='breadcrumbs'>
                <ul>
                  <li><a href='#'>Home</a></li>
                  <li><span>FAQ</span></li>
                </ul>
              </div> */}
            <div className="heading_info">
              <h1>
                {t("Frequently asked questions")} <span>{"(FAQs)"}</span>
              </h1>
            </div>

            <div className="faq_content" id="id_hpv">
              <div className="faq_item_opt">
                <div
                  className={`faq_item_block ${
                    isOpenopt === "HPV" ? "active_item" : ""
                  }`}
                  onClick={() => toggle("HPV")}
                >
                  <div className="faq_item_block_pic">
                    <img
                      src={isOpenopt === "HPV" ? IconHPV : IconHPV2}
                      alt="hpv"
                    />
                  </div>
                  <div className="faq_item_info ">
                    <h3>{t("HPV")}</h3>
                    <p>({t("Human Papilloma virus")})</p>
                  </div>
                </div>
                <div
                  className={`faq_item_block ${
                    isOpenopt === "STIs" ? "active_item" : ""
                  }`}
                  onClick={() => toggle("STIs")}
                >
                  <div className="faq_item_block_pic">
                    <img
                      src={isOpenopt === "STIs" ? IconSTIS : IconSTIS4}
                      alt="STIs"
                    />
                  </div>
                  <div className="faq_item_info">
                    <h3>{t("STIs")}</h3>
                    <p>({t("sexually transmitted infections")})</p>
                  </div>
                </div>
                <div
                  className={`faq_item_block ${
                    isOpenopt === "SEXUAL" ? "active_item" : ""
                  }`}
                  onClick={() => toggle("SEXUAL")}
                >
                  <div className="faq_item_block_pic">
                    <img
                      src={isOpenopt === "SEXUAL" ? IconSH : IconSH6}
                      alt="SEXUAL"
                    />
                  </div>
                  <div className="faq_item_info">
                    <h3>
                      {t("SEXUAL")} {t("HEALTH")}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="faq_item_view">
                {isOpenopt === "HPV" && <Hpvinfo />}

                {isOpenopt === "STIs" && <Stis />}

                {isOpenopt === "SEXUAL" && <SexualHealth />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
