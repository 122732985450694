import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <Header />

      <main className="flex-1">{children}</main>

      <Footer />
    </div>
  );
};

export default Layout;