import Layout from "../Layout";
import "../Aboutus/aboutus.css";

import "./Productpage.css";
import "./ProductpageCustom.css";
import img1 from "../assets/image/updatedicon/prod1.JPG";
import img2 from "../assets/image/updatedicon/prod2.png";
import img3 from "../assets/image/perifit.png";
import ava_buttonArrow from "../assets/image/ava-buttonArrow.png";
import aboutPageImage from "../assets/image/about-section-new-image.jpg";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import content_banner_image from "../assets/image/product-control-content-image.jpg";
import { Badge, Card } from "react-bootstrap";
import CollectSpecimen from "./CollectSpecimen";
import { useRef } from "react";

const Index = () => {
  const { t, i18n } = useTranslation();
  const takeControlContentRef = useRef(null);

  return (
    <Layout>
      <section
        // style={{
        //   minHeight: 100,
        //   height:
        //     window.innerHeight -
        //     120 -
        //     takeControlContentRef?.current?.offsetHeight,
        // }}
        className="product-top-banner"
      >
        <div className="container pt-4">
          <div className="innerBreadcum">
            <span>{t("home")}</span> <i class="fa-solid fa-angle-right"></i>{" "}
            {t("ava")}
          </div>
        </div>
      </section>

      <section className="take-control">
        <div ref={takeControlContentRef} className="container">
          <div className="control-content">
            <h2>
              {t("product_hero_title")}
              {i18n.language !== "th" && <span> {t("ava")}</span>}
              <br />
              {t("product_hero_sub_title")}.
            </h2>
            <p>{t("product_hero_description")}</p>
          </div>
        </div>
        <Card className="content-bottom">
          <Card.Img src={content_banner_image}></Card.Img>
          <Card.Body>
            <h5 className="paragraphtext">{t("product_hero_description_2")}</h5>
            <h5 className="paragraphtext">
              {t("product_hero_description_3")}{" "}
            </h5>
          </Card.Body>
        </Card>
      </section>
      <div className="product-top-card avaBg">
        <div className="row avaproduct">
          <h2>
            {t("ava_product_before")} <span className="avacolor">AVA</span>{" "}
            {t("ava_product_after")}
          </h2>
          <Link
            to="/ava?product=hpv"
            className="col-md-6 flex items-center justify-end"
          >
            <div className="ava-prod-contnt">
              <Badge
                style={{ width: "fit-content", padding: "0px 10px" }}
                bg="hpv"
              >
                {t("hpv")}
              </Badge>
              <img src={img1} alt="#" className="main-img h-96 !w-[36rem]" />
              <button className="btn details">
                {t("see_detail")}{" "}
                <img className="sm-arrow" src={ava_buttonArrow} alt="#" />
              </button>
            </div>
          </Link>
          <Link
            to="/ava?product=stis"
            className="col-md-6 flex items-center justify-start"
          >
            <div className="ava-prod-contnt">
              <Badge
                style={{ width: "fit-content", padding: "0px 10px" }}
                className="w-full"
                bg="sti"
              >
                {t("stis")}
              </Badge>
              <img src={img2} alt="#" className="main-img h-96 !w-[36rem]" />
              <button className="btn details">
                {t("see_detail")}{" "}
                <img className="sm-arrow" src={ava_buttonArrow} alt="#" />
              </button>
            </div>
          </Link>
          <Link
            to="/ava?product=perifit"
            className="col-md-6 flex items-center justify-end"
          >
            <div className="ava-prod-contnt">
              <Badge
                style={{ width: "fit-content", padding: "0px 10px" }}
                className="w-full"
                bg="sti"
              >
                {t("perifit")}
              </Badge>
              <img src={img3} alt="#" className="main-img h-96 !w-[36rem]" />
              <button className="btn details">
                {t("see_detail")}{" "}
                <img className="sm-arrow" src={ava_buttonArrow} alt="#" />
              </button>
            </div>
          </Link>
        </div>
      </div>
      <section className="workcontent">
        <div className="row">
          <h2>
            {t("how_it")}
            <span className="avacolor ms-3">{t("works")}</span>
          </h2>
          <div className="col-md-3 bgcolordiv">
            <h1>1</h1>
            <h4>{t("register")}</h4>
            <p>{t("register_description")}</p>
          </div>
          <div className="col-md-3 bgcolordiveven">
            <h1>2</h1>
            <h4>{t("collect")} </h4>
            <p>{t("collect_description")}</p>
          </div>
          <div className="col-md-3 bgcolordiv">
            <h1>3</h1>
            <h4>{t("free_delivery")} </h4>
            <p>{t("free_delivery_description")}</p>
          </div>
          <div className="col-md-3 bgcolordiveven">
            <h1>4</h1>
            <h4>{t("results")} </h4>
            <p>{t("results_description")}</p>
          </div>
        </div>
      </section>

      <CollectSpecimen />

      <div className="aboutdiv body-text">
        <Card>
          <Card.Body>
            <Card.Title as="h3">
              {t("about")} <span className="avacolor">{t("our_lab")}</span>
            </Card.Title>
            <Card.Text>{t("about_our_lab_description")}</Card.Text>
          </Card.Body>
          <Card.Img
            className="about-image"
            variant="top"
            src={aboutPageImage}
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Index;
