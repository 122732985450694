import React, { useState, useEffect, useMemo } from "react";
import Layout from "../Layout";
import shipping1 from "../assets/image/shipping1.png";
import shipping2 from "../assets/image/shipping2.png";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "../Thankyou/style.css";
import { PAYMENT_API_BASE_URL } from "../env";

const Thankyou = () => {
  const { t, i18n } = useTranslation();
  const search = useLocation().search;
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { invoiceNumber, email } = useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      invoiceNumber: query.get("invoice-no"),
      email: query.get("email"),
    };
  }, [search]);

  useEffect(() => {
    const fetchOrder = async (invoiceNumber, email) => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `${PAYMENT_API_BASE_URL}/payments/purchases/${invoiceNumber}/${email}`
        );
        const data = await response.json();

        if (response.status !== 200) {
          toast.error("Failed to fetch order");
          return;
        }

        setOrder(data);
      } catch (error) {
        toast.error("Failed to fetch order");
      } finally {
        setIsLoading(false);
      }
    };

    if (invoiceNumber && email) {
      fetchOrder(invoiceNumber, email);
    }
  }, [search, invoiceNumber, email]);

  if (!invoiceNumber || !email) {
    return (
      <Layout>
        <p className="!h-[calc(100vh-235px)] flex items-center justify-center text-red-500">
          {t(
            "Email or Invoice Number is missing in the URL Query, Please contact the admininistrator"
          )}
        </p>
      </Layout>
    );
  }

  return (
    <Layout>
      {isLoading ? (
        <div className="bg-gray-300 opacity-10 animate-pulse !w-[100%] !h-screen" />
      ) : order.status === "Success" ? (
        <div className="container customContainer avaBg">
          <div className="row mt-4 pb-3">
            <div className="col-md-12">
              <div className="thankyouBase pb-5">
                <h2 className="pb-4">{t("Thank you")}</h2>
                <h5 className="pb-2">
                  {t("Your order")} #{order.invoiceNo} {t("has been placed")}!
                </h5>
                <h6>
                  {t("We sent an email to")} {order.email}{" "}
                  {t(
                    "with your order confirmation and receipt. If the email hasn't arrived within two minutes, please check your spam folder to see if the email was routed there"
                  )}
                  .
                </h6>
                <div className="shippingBase pt-3 pb-5">
                  <div className="shippignBlock">
                    <img src={shipping1} alt="" />
                    <div>
                      <h4>{t("Shipping")}</h4>
                      <h5>{order.name}</h5>
                      <p>
                        {order.address}, {order.district}, {order.province}{" "}
                        {order.postCode} <br />
                        {order.phoneNumber} {order.email}
                      </p>
                    </div>
                  </div>
                  <div className="shippignBlock">
                    <img src={shipping2} alt="" />
                    <div>
                      <h4>{t("Shipping method")}</h4>
                      <p>
                        {t("Preferred Method")}: <br />
                        {order.address},{order.district}
                        <br />({t("normally 2-5 business days")}){" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7 ordrList">
                    <h3>{t("Order List")}</h3>
                    <div className="flex flex-col gap-2">
                      {order.purchaseItems?.map((item) => (
                        <div className="odlBlock mb-4">
                          <img src={item.imageUrl} alt={item.name} />
                          <div>
                            <h5>
                              {i18n.language === "th" ? item.nameTh : item.name}
                            </h5>
                            <p className="line-clamp-2">
                              {i18n.language === "th"
                                ? item.descriptionTh
                                : item.description}
                            </p>
                            <p>
                              <span>
                                {t("Quantity")} : {item.quantity}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="odlBtn">
                      <button class="commonButton">
                        {t("Continue Shopping")}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="orderSummary">
                      <h4>{t("Order Summary")}</h4>
                      <div className="cartSubtotalbg pt-4 pb-4">
                        <p>
                          {t("Subtotal")}{" "}
                          <span>
                            {new Intl.NumberFormat("th-TH", {
                              style: "currency",
                              currency: "THB",
                              maximumFractionDigits: 0,
                            }).format(order.subtotal)}
                          </span>
                        </p>
                        <p>
                          {t("Shipping")}{" "}
                          <span>
                            {t("฿")}{" "}
                            {new Intl.NumberFormat("th-TH", {
                              style: "currency",
                              currency: "THB",
                              maximumFractionDigits: 0,
                            }).format(order.shippingFee)}
                          </span>
                        </p>
                      </div>
                      <div className="cartTotal pt-4 pb-4">
                        <strong>{t("Total")}</strong>{" "}
                        <strong>
                          {new Intl.NumberFormat("th-TH", {
                            style: "currency",
                            currency: "THB",
                            maximumFractionDigits: 0,
                          }).format(order.total)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container customContainer avaBg ">
          <div className="row mt-4 pb-3">
            <div className="col-md-12 flex flex-col">
              <div className="thankyouBase pb-5 !h-[calc(100vh-235px)] flex flex-col items-center justify-center">
                <h2 className="pb-4">{t("We're Sorry")}</h2>
                <h5 className="pb-2">
                  {t("There was an issue processing your order")} #
                  {order.invoiceNo ?? invoiceNumber}!
                </h5>
                {/* <h6>{order.message ?? t("Unknown error occured")}</h6> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Thankyou;
