import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BannerCard = ({ title, description, image, index }) => {
  const { t } = useTranslation();
  return (
    <div className="banner_add">
      <Link to={t(`banner_slug_${index + 1}`)} key={index} target="_blank">
        {/* </Link> */}
        {/* <Link
        to="/pedsupab/blog/E-Ciggarettes_and_Cervical_Cancer"
        target="_blank"
      > */}
        <img src={image} alt="" />
        <div className="cardcontainer">
          <div>
            <h3 className="sliderHeaderText">{t(title)}</h3>
            <p className="sliderParaText">{t(description)}</p>

            <div>
              <button className="seeDetails">{t("SEE DETAILS")}</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BannerCard;
