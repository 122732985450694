import React from "react";
import BannerCard from "./BannerCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { t } from "i18next";
import { Link } from "react-router-dom";

const Banner = () => {
  console.log(t(`banner_image_${0 + 1}.jpg`));

  return (
    <div className="banner_option">
      <OwlCarousel
        className="owl-theme"
        loop
        items={1}
        margin={0}
        autoplay={true}
        autoplayTimeout={5000}
        nav
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <div class="item slideropt" key={index}>
            <BannerCard
              image={t(`banner_image_${index + 1}`)}
              title={t(`banner_title_${index + 1}`)}
              description={t(`banner_description_${index + 1}`)}
              index={index}
            />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Banner;
