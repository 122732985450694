import React from "react";
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import logo from "../assets/image/kn-logo.png";
import fbIcon from "../assets/image/icons/facebook.svg";
import instagram from "../assets/image/icons/instagram.svg";
import youtube from "../assets/image/icons/youtube.svg";
import tiktok from "../assets/image/icons/tiktok.svg";
import twitter from "../assets/image/icons/twitter.svg";
import line from "../assets/image/line_icon.png";
import { t } from "i18next";
import gotoTop from "./../assets/image/goto-top-white.png";

const Footer = () => {
  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col md={6} style={{ textAlign: "left" }}>
            <img src={logo} alt="#" className="img-fluid" />
          </Col>
          <Col md={6}>
            <Card className="footer-card">
              <Card.Title>{t("about_us")}</Card.Title>
              <Card.Text className="white">{t("about_us_details")}</Card.Text>

              <Nav>
                <Nav.Item className="me-3">{t("follow_us")}</Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://www.facebook.com/pedsupab.official"
                  >
                    <img src={fbIcon} alt="#" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://www.instagram.com/pedsupab.official/"
                  >
                    <img src={instagram} alt="#" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://www.youtube.com/@Healthspanofficial"
                  >
                    <img src={youtube} alt="#" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://www.tiktok.com/@pedsupab.official"
                  >
                    <img src={tiktok} alt="#" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link target="_blank" href="https://x.com/pedsupab?mx=2">
                    <img src={twitter} alt="#" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://line.me/R/ti/p/@723pzgzk?oat_content=url"
                  >
                    <img src={line} alt="#" style={{ width: "40px" }} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Col>
        </Row>
      </Container>
      <section>
        <Nav className="privacy-policy">
          <Nav.Item>
            <Nav.Link>&#9679; {t("privacy_policy")}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link>&#9679; {t("terms_and_conditions")}</Nav.Link>
          </Nav.Item>
        </Nav>
      </section>
    </footer>
  );
};

export default Footer;
