import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import IconSTIS4 from "../assets/image/icons/FAQ_STIs-04.png";

const Stis = () => {
  const { t } = useTranslation();
  return (
    <div className="faq_view_details opt1">
      <div className="faq_heading">
        <div className="faq_item_block">
          <div className="faq_item_block_pic">
            <img src={IconSTIS4} alt="STIs" />
          </div>
          <div className="faq_item_info">
            <h3>{"STIs"}</h3>
            <p>({t("sexually transmitted infections")})</p>
          </div>
        </div>
      </div>
      <div className="faq_des_block text-left">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {t("What’s the difference between STI and STD?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "STD stands for “sexually transmitted disease,” and STI stands for “sexually transmitted infections.” Simply put, they are both the same: infections that get passed from one person to another during intercourse."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {t("How often should I get tested for STI?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "The frequency of getting tested for STI depends on the person and their relationships. For someone who has more than one partner and is sexually active, we would recommend getting tested once every three months. However, getting tested and being safe cannot hurt anyone! So even if you’re in a long-term relationship, it’s good to get you and your partner tested. Getting tested before starting a serious relationship or ending one could also be a good measure."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              {t("Do condoms protect from STIs?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "While condoms are an effective form of protection against sexually transmitted infections (STIs), it's important to note that they may not provide 100% protection. STIs can be contracted through skin-to-skin contact, which means that areas not covered by condoms, such as the genital area, can still be at risk. Engaging in foreplay, oral sex, or using sex toys can also pose risks of contracting STIs."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {t(
                "How do I tell my sexual partner to get STI tested without breaching trust?"
              )}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "When discussing STI testing with your sexual partner, it's important to approach the conversation with empathy, understanding, and a focus on mutual health and well-being."
              )}
              {t("Here are a few things to consider:")}
              <ol style={{ listStyle: "numbered" }}>
                <li>{t("Choose the right time and place")}</li>
                <li>{t("Be honest and open")}</li>
                <li>{t("Educate and normalize ")}</li>
                <li>{t("Use “we” instead of “you”")}</li>
                <li>{t("Share your own commitment")}</li>
                <li>{t("Respect privacy and confidentiality")}</li>
                <li>{t("Address concerns and fears")}</li>
                <li>{t("Offer resources and options")}</li>
                <li>{t("Maintain a non-judgmental attitude")}</li>
              </ol>
              {t(
                "Read our full blog on this topic on our knowledge page for more."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              {t("Does everyone who has STI have symptoms?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "Many STIs have no signs or symptoms. Some people have STIs without knowing but can carry the infection to others. The only way to know for sure is to get tested. However, it is also important to use protection."
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              {t("What are key symptoms of STDs?")}
            </Accordion.Header>
            <Accordion.Body>
              {t(
                "If you're worried because you think you've got an STD, go for a check-up at a sexual health clinic as soon as you can."
              )}
              {t(
                "Do not have sex, including oral sex, without using a condom until you've had a check-up."
              )}
              {t(
                "You can have an STI without knowing it and infect your partner during sex."
              )}
              <p className="my-2">{t("STD symptoms")}</p>
              {t("The symptoms of an STD can include:")}
              <ol style={{ listStyle: "numbered" }}>
                <li>
                  {t("an unusual discharge from the vagina, penis or anus")}
                </li>
                <li>{t("pain when peeing")}</li>
                <li>
                  {t(
                    "lumps or skin growths around the genitals or bottom (anus)"
                  )}
                </li>
                <li>{t("a unusual rash")}</li>
                <li>{t("abnormal vaginal bleeding")}</li>
                <li>{t("itchy genitals or anus")}</li>
                <li>{t("blisters and sores around your genitals or anus")}</li>
                <li>{t("warts around your genitals or anus")}</li>
                <li>
                  {t("warts in your mouth or throat, but this is very rare.")}
                </li>
              </ol>
              {t(
                "It's Important to note that not everyone shows symptoms, thus getting tested for STIs are important for your health and of others."
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Stis;
