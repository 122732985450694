import { useState } from "react";

const links = [
  {
    icon: "fa-brands fa-facebook-f",
    name: "Facebook",
    color: "bg-[#1877F2]",
    url: "https://www.facebook.com/pedsupab.official",
  },
  {
    icon: "fa-brands fa-instagram",
    name: "Instagram",
    color: "bg-[#DD2A7B]",
    url: "https://www.instagram.com/pedsupab.official/?igsh=a2ZzdDN6dncyNHYw",
  },
  {
    icon: "fa-brands fa-youtube",
    name: "Youtube",
    color: "bg-[#FF0000]",
    url: "https://www.youtube.com/@Healthspanofficial",
  },
  {
    icon: "fa-brands fa-tiktok",
    name: "Tiktok",
    color: "bg-[#EE1D52]",
    url: "https://www.tiktok.com/@pedsupab.official",
  },
  {
    icon: "fa-brands fa-twitter",
    name: "Twitter",
    color: "bg-[#1DA1F2]",
    url: "https://x.com/pedsupab?mx=2",
  },
  {
    icon: "fa-brands fa-line",
    name: "Line",
    color: "bg-[#00B900]",
    url: "https://line.me/R/ti/p/@723pzgzk?oat_content=url",
  },
];

export default function SocialMediaLinks() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-10 sm:right-20 right-6 gap-2 flex flex-col justify-center items-center z-50">
      {isOpen &&
        links.map((link) => (
          <div
            onClick={() => window.open(link.url, "_blank")}
            key={link.name}
            className={
              "relative flex items-center justify-center h-12 w-12 hover:scale-125 rounded-full cursor-pointer transition ease-in-out delay-100 " +
              link.color
            }
          >
            <p
              className={
                "text-white px-2 rounded absolute right-full top-1/2 -translate-y-1/2 mr-2 " +
                link.color
              }
            >
              {link.name}
            </p>

            <i
              style={{
                fontSize: "1.5rem",
              }}
              className={"text-white " + link.icon}
            />
          </div>
        ))}

      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center h-14 w-14 bg-yellow-600 rounded-full cursor-pointer"
      >
        {isOpen ? (
          <i
            style={{
              fontSize: "1.2rem",
            }}
            className="fa-solid fa-x text-white"
          />
        ) : (
          <i
            style={{
              fontSize: "1.2rem",
            }}
            className="fa-solid fa-chevron-up text-white"
          />
        )}
      </div>
    </div>
  );
}
