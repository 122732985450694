import React, { useEffect, useState } from "react";
import logo from "../assets/image/hsp-logo.svg";
import search from "../assets/image/searchIcon.png";
import crt1 from "../assets/image/crt.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, DropdownMenu, Nav, Navbar } from "react-bootstrap";
import { useCart } from "../contexts/CartContext";

function Header() {
  const { cart } = useCart();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [currentpage, setCurrentPage] = useState();

  // TODO: Refactor this to use a single state for dropdowns
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [showPedsupabDropdown, setShowPedsupabDropdown] = useState(false);

  const navigate = useNavigate();

  const cartLength = cart.reduce((acc, item) => acc + item.quantity, 0);

  const changeLanguage = (value) => {
    const currentPath = location.pathname;
    const currentLanguage = i18n.language;
    const newPath = currentPath.replace("/" + currentLanguage, "/" + value);
    navigate(newPath + location.search);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPageName = pathSegments[pathSegments.length - 1];
    setCurrentPage(currentPageName);
  }, [location.pathname]);

  return (
    <header className="site-header newBgcolor">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} alt="" className="logo" />
          </Navbar.Brand>
          <div className="flex flex-row-reverse items-center gap-2">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Nav.Item className="min-[992px]:hidden">
              {/* <button className={'btn nav-link'}> */}
              <select
                className="text-lg font-semibold w-[70px] rounded px-2"
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18n.language}
              >
                {i18n.languages.map((lang) => (
                  <option value={lang} selected={i18n.language === lang}>
                    {lang.toUpperCase()}
                  </option>
                ))}
              </select>
            </Nav.Item>
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="header-right-column"
          >
            <Nav as="ul" className="ms-auto nav-menu flex items-center">
              <Nav.Item show as="li" className="relative">
                <div
                  className={`nav-link ${currentpage === "product" || currentpage === "ava"
                    ? "active"
                    : ""
                    } !flex items-center`}
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="navbarDropdown"
                  onMouseEnter={() => setShowProductDropdown(true)}
                  onMouseLeave={() => setShowProductDropdown(false)}
                >
                  <Link to="/product" className="text-[#61422c] text-[18px]">
                    {t("product")}
                  </Link>
                  <div
                    onClick={(e) => {
                      // e.stopPropagation();
                      // e.preventDefault();
                      setShowPedsupabDropdown(!showPedsupabDropdown);
                    }}
                  >
                    <i className="fa fa-angle-down ml-2"></i>
                  </div>
                </div>
                <DropdownMenu
                  show={showProductDropdown}
                  className="dropdown-menu absolute"
                  aria-labelledby="navbarDropdown"
                  onMouseEnter={() => setShowProductDropdown(true)}
                  onMouseLeave={() => setShowProductDropdown(false)}
                >
                  <a className="dropdown-item" href="/ava?product=hpv">
                    {t("hpv")}
                  </a>
                  <a className="dropdown-item" href="/ava?product=stis">
                    {t("sti")}
                  </a>
                  <a className="dropdown-item" href="/ava?product=perifit">
                    {t("perifit")}
                  </a>
                </DropdownMenu>
              </Nav.Item>
              <Nav.Item as="li" className="relative">
                <div
                  className={`nav-link ${currentpage === "pedsupab" ? "active" : ""
                    } !flex items-center`}
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="navbarDropdown"
                  onMouseEnter={() => setShowPedsupabDropdown(true)}
                  onMouseLeave={() => setShowPedsupabDropdown(false)}
                >
                  <Link to="/pedsupab" className="text-[#61422c] text-[18px]">
                    {t("pedsupab")}
                  </Link>
                  <div
                    onClick={(e) => {
                      // e.stopPropagation();
                      // e.preventDefault();
                      setShowPedsupabDropdown(!showPedsupabDropdown);
                    }}
                  >
                    <i className="fa fa-angle-down ml-2"></i>
                  </div>
                </div>
                <DropdownMenu
                  show={showPedsupabDropdown}
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  onMouseEnter={() => setShowPedsupabDropdown(true)}
                  onMouseLeave={() => setShowPedsupabDropdown(false)}
                >
                  <a
                    className="dropdown-item"
                    href="/pedsupab/blog/category/sex-counsel"
                  >
                    {t("sex_counsel")}
                  </a>
                  <a
                    className="dropdown-item"
                    href="/pedsupab/blog/category/pillow-talk"
                  >
                    {t("Pillow Talk")}
                  </a>
                  <a className="dropdown-item" href="/sexualcare">
                    {t("Sexual Care")}
                  </a>
                  <a className="dropdown-item" href="/video">
                    {t("Video")}
                  </a>
                </DropdownMenu>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink
                  className={"nav-link"}
                  active={currentpage === "faq"}
                  to="/faq"
                >
                  {t("faqs")}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink
                  className={"nav-link"}
                  active={currentpage === "aboutus"}
                  to="/aboutus"
                >
                  {t("about_us")}
                </NavLink>

                {/* <NavDropdown title={t("about_us")} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    active={currentpage === "aboutus"}
                    href="/aboutus#id_overview"
                  >
                    {t("overview")}
                  </NavDropdown.Item>

                </NavDropdown> */}
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink
                  className={"nav-link"}
                  active={currentpage === "affiliates"}
                  to="/affiliates"
                >
                  {t("affiliates")}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <button className={"srchicn btn nav-link"}>
                  <img src={search} alt="" />
                </button>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink
                  active={currentpage === "cart"}
                  to="/cart"
                  className={"cartBg nav-link"}
                >
                  <div className="relative w-fit">
                    <img src={crt1} alt="" />
                    {cartLength > 0 && (
                      <div className="absolute w-4 h-4 flex items-center justify-center rounded-full bg-red-500 -bottom-1 -right-1">
                        <span className="text-xs text-white">{cartLength}</span>
                      </div>
                    )}
                  </div>
                </NavLink>
              </Nav.Item>
              <Nav.Item className="max-[991px]:hidden">
                {/* <button className={'btn nav-link'}> */}
                <select
                  className="text-lg font-semibold w-[70px] rounded px-2"
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={i18n.language}
                >
                  {i18n.languages.map((lang) => (
                    <option value={lang} selected={i18n.language === lang}>
                      {lang.toUpperCase()}
                    </option>
                  ))}
                </select>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
