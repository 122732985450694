import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import useWindowSize from "../hooks/useWindowSize";

const RecentVideo = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="aboutContaianer defpadding">
      <div className="video_adddetails">
        <h1 className="video_text_container">
          <span className="recent_vdieo">{t("recent")}</span>
          {"  "}
          <span className="article">{t("videos")}</span>
        </h1>
        <div className="desktop_slider lg:w-[1000px] m-auto">
          <OwlCarousel
            className="owl-theme video_carousel"
            loop
            items={1}
            margin={15}
            center
            stagePadding={width > 1000 ? 180 : undefined}
            nav
          >
            {[
              {
                link: "https://youtube.com/embed/xlqPYOTwQFo?si=8NkmKpZxYnKLMxs_",
              },
              {
                link: "https://youtube.com/embed/Wb95n-3_YrM?si=7KjFHxgIKnO1KSrn",
              },
              {
                link: "https://youtube.com/embed/if7peOZ7pXc?si=-RS93vA6LbDkmk76",
              },
              {
                link: "https://youtube.com/embed/ZuAMIxeFSNQ?si=q-sG3VjG1jmgYima",
              },
              {
                link: "https://youtube.com/embed/qe2TWhdFL-A?si=stAjbPPpjD2HsKX-",
              },
            ].map((video, i) => (
              <div className="video_item flex items-center justify-center">
                <div className="graphic">
                  <iframe
                    src={video.link}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    title="video"
                    className="framevideo md:!w-[640px] md:!h-[360px] aspect-video"
                    width={640}
                    height={360}
                  />
                </div>

                {/* <h5 className="detailstext">{video.title}</h5> */}
              </div>
            ))}
          </OwlCarousel>
        </div>
        <div className="mob_slider">
          <OwlCarousel
            className="owl-theme"
            loop
            items={1}
            margin={15}
            autoplay={true}
            nav
          >
            {[
              {
                link: "https://youtube.com/embed/xlqPYOTwQFo?si=8NkmKpZxYnKLMxs_",
              },
              {
                link: "https://youtube.com/embed/Wb95n-3_YrM?si=7KjFHxgIKnO1KSrn",
              },
              {
                link: "https://youtube.com/embed/if7peOZ7pXc?si=-RS93vA6LbDkmk76",
              },
              {
                link: "https://youtube.com/embed/ZuAMIxeFSNQ?si=q-sG3VjG1jmgYima",
              },
              {
                link: "https://youtube.com/embed/qe2TWhdFL-A?si=stAjbPPpjD2HsKX-",
              },
            ].map((video, i) => (
              <div className="video_item">
                <div className="graphic">
                  <iframe
                    src={video.link}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    title="video"
                    className="framevideo"
                  />
                </div>

                {/* <h5 className="text-[#785338] break-words truncate">
                  {video.title.slice(0, 10)}
                </h5> */}
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default RecentVideo;
