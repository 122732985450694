import React, { useState } from "react";
import Header from "../header";
import {
  Breadcrumb,
  Button,
  Card,
  Col, Form,
  Nav,
  Row
} from "react-bootstrap";
import lineIcon from "../../../src/assets/image/line_icon.png";
import linkedinIcon from "../../../src/assets/image/linkedin_icon.png";
import facebookIcon from "../../../src/assets/image/facebook_icon.png";
import Footer from "../footer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { AFFILIATE_API_BASE_URL } from "../../env";

export const SEclinic = () => {
  const { t, i18n } = useTranslation();
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    description: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    const { name, email, description } = formdata;

    const response = await fetch(
      `${AFFILIATE_API_BASE_URL}/customers/mail-to-us?lang=${i18n.language}`,
      {
        method: "POST",
        body: JSON.stringify({
          name,
          email,
          message: description,
        }),
      }
    );

    if (response.ok) {
      toast.success(t("Message sent successfully"));
      setFormdata({
        email: "",
        name: "",
        description: "",
      });
    } else {
      toast.error(
        t(
          "Couldn't send the message due to some technical issue, Please try again later."
        )
      );
    }
  };
  return (
    <main className="main se-clinic">
      <Header />
      <div className="kn-dtls-page-content">
        <Breadcrumb className="cs-breadcum">
          <Breadcrumb.Item>
            {" "}
            <NavLink to={"/pedsupab"}>Pedsupab</NavLink>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Sexual Care")}</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="se-form-wrapper">
          <Card.Title>Sex Education Clinic</Card.Title>
          <Card.Subtitle>
            {t("If you have any question about sex education, Ask us !")}
          </Card.Subtitle>
          <Nav className="social-media">
            <Nav.Item>
              <Nav.Link href="https://lin.ee/EIGynOY" target="_blank">
                <img src={lineIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/pedsupab.official/?igsh=a2ZzdDN6dncyNHYw">
                <img src={linkedinIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.facebook.com/pedsupab.official">
                <img src={facebookIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <p className="or">{t("Or")}</p>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="contact-form mt-4"
          >
            <Row>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="Name">
                  <Form.Label>
                    {t("Name")}
                    <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    minLength={3}
                    value={formdata.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="Email">
                  <Form.Label>
                    {t("Email")}
                    <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formdata.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="description"
                    minLength={3}
                    value={formdata.description}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button type="submit" className="detailsbtn">
                  {t("Send")}
                </Button>
                <p className="mt-3">
                  {t(
                    "Your email address will not be published. Required fields are marked"
                  )}{" "}
                  *
                </p>
              </Col>
            </Row>
          </Form>
        </Card>
        {/* <Card className="se-form-wrapper">
          <Card.Title>Sex Education Clinic</Card.Title>
          <Card.Subtitle>
            {t("If you have any question about sex education, Ask us !")}
          </Card.Subtitle>
          <Nav className="social-media">
            <Nav.Item>
              <Nav.Link href="https://lin.ee/EIGynOY" target="_blank">
                <img src={lineIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/pedsupab.official/?igsh=a2ZzdDN6dncyNHYw">
                <img src={linkedinIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.facebook.com/pedsupab.official">
                <img src={facebookIcon} alt="line-icon" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <p className="or">{t("Or")}</p>

          <Form className="contact-form mt-4">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="Name">
                  <Form.Label>
                    {t("Name")}
                    <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formdata.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="Email">
                  <Form.Label>
                    {t("Email")}
                    <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formdata.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="description"
                    value={formdata.description}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button className="detailsbtn" onClick={handleSubmit}>
                  Send
                </Button>
                <p className="mt-3">
                  {t(
                    "Your email address will not be published. Required fields are marked"
                  )}{" "}
                  *
                </p>
              </Col>
            </Row>
          </Form>
        </Card> */}
      </div>
      <Footer />
    </main>
  );
};
