import { createContext } from "react";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { signin, signout, getUserInfo } from "../apis/auth";

const initialAuth = { user: null, isSignedIn: false };
const AuthContext = createContext(initialAuth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const isSignedIn = !!localStorage.getItem("access");
  const { data: user } = useQuery(["user"], getUserInfo, {
    enabled: isSignedIn,
    refetchOnWindowFocus: false,
  });

  const setAuth = (data) => {
    localStorage.setItem("access", data.jwtToken);
    localStorage.setItem("refresh", data.refreshToken);
  };

  const clearAuth = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };

  const signinMutation = useMutation(signin, {
    onSuccess: (data) => {
      setAuth(data);
      toast.success("Signed in successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const signoutMutation = useMutation(signout, {
    onSuccess: () => {
      clearAuth();
      toast.success("Signed out successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return (
    <AuthContext.Provider
      value={{
        user: user ?? null,
        isSignedIn,
        signin: signinMutation,
        signout: signoutMutation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
