import { classNames } from "../utils";

export default function Button({
  loading,
  variant = "primary",
  progress,
  children,
  className,
  disabled,
  ...props
}) {
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={classNames(
        "flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm disabled:cursor-not-allowed disabled:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        progress && "relative !bg-transparent",
        variant === "primary" &&
          "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
        variant === "secondary" &&
          "bg-slate-600 hover:bg-slate-500 focus-visible:outline-slate-600",
        variant === "danger" &&
          "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600",
        variant === "alert" &&
          "bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600",
        variant === "success" &&
          "bg-green-600 hover:bg-green-500 focus-visible:outline-green-600",
        className
      )}
    >
      {loading ? (
        <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg>
      ) : (
        children
      )}
      {!!progress && (
        <div
          className={classNames(
            "h-full absolute top-0 left-0 rounded-md -z-10",
            variant === "primary" && "bg-indigo-300",
            variant === "secondary" && "bg-slate-300",
            variant === "danger" && "bg-red-300",
            variant === "alert" && "bg-yellow-300",
            variant === "success" && "bg-green-300"
          )}
          style={{ width: `${progress}%` }}
        ></div>
      )}
    </button>
  );
}
