import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./main.css";
import LandingPage from "./LandingPage";
import Index from "./Ava/Index";
import Faq from "./Faq/Faq";
import Aboutus from "./Aboutus/Aboutus";
import Affiliates from "./Affiliates/Affiliates";
import Cart from "./Cart/Cart";
import Productpage from "./Productpage/Productpage";
import Thankyou from "./Thankyou/Thankyou";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Knowledge from "./Knowledge";
import { SEclinic } from "./Knowledge/SEclinic/index.jsx";
import Blogpage from "./Knowledge/Blog/index.jsx";
import Videopage from "./Knowledge/Video/index.jsx";
import ScrollToTop from "./utils/ScrollToTop.jsx";
import { CartProvider } from "./contexts/CartContext.jsx";
import Signin from "./Auth/Signin.jsx";
import BlogForm from "./Blog/BlogForm.jsx";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { QueryClient, QueryClientProvider } from "react-query";
import IsNotAuthenticated from "./utils/IsNotAuthenticated.jsx";
import NotFound from "./utils/NotFound.jsx";
import BlogCategoryPage from "./Knowledge/Blog/Category.jsx";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <ScrollToTop />
      <AuthProvider>
        <CartProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/en" />} />
            <Route path="/:lang/*" element={<LanguageRoutes />} />
          </Routes>
        </CartProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function LanguageRoutes() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (i18n.languages.includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      if (location.pathname.startsWith("/" + i18n.language)) {
        return;
      }

      navigate("/" + i18n.language + location.pathname + location.search, {
        replace: true,
      });
    }
  }, [lang, i18n, navigate, location]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/ava" element={<Index />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/affiliates" element={<Affiliates />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/product" element={<Productpage />} />
      <Route path="/pedsupab" element={<Knowledge />} />
      <Route path="/sexualcare" element={<SEclinic />} />
      <Route path="/pedsupab/blog/:blogId" element={<Blogpage />} />
      <Route
        path="/pedsupab/blog/category/:categorySlug"
        element={<BlogCategoryPage />}
      />
      <Route path="/video" element={<Videopage />} />
      <Route path="/video/:videoId" element={<Videopage />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/blog" element={<BlogForm />} />
      <Route element={<IsNotAuthenticated />}>
        <Route path="/signin" element={<Signin />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
