import React from "react";
import Layout from "../Layout";
import Banner from "./Banner";
import AboutHeathSpan from "./AboutHeathSpan";
import Articles from "./Articles";
import RecentVideo from "./RecentVideo";
import NewsUpdate from "./NewsUpdate";
import FrequestAsk from "./FrequestAsk";

const LandingPage = () => {
  return (
    <Layout>
      <div>
        <Banner />
        <AboutHeathSpan />
        <Articles />
        <RecentVideo />
        <NewsUpdate />
        <FrequestAsk />
      </div>
    </Layout>
  );
};

export default LandingPage;
