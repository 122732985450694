import Layout from "../Layout";
import cartIcon from "../../src/assets/image/crt.png";
import img1 from "../assets/image/img1.png";
import otherproduct from "../assets/image/otherproduct.png";
import perifit from "../assets/image/perifit.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useCart } from "../contexts/CartContext";
import { useEffect, useMemo, useState } from "react";
import { PAYMENT_API_BASE_URL } from "../env";

const itemPrice = {
  hpv: 1600,
  stis: 2650,
  perifit: 5900
};

const shippingCharge = 50;

const Cart = () => {
  const { cart, addProductToCart, clearCart } = useCart();
  const { t, i18n } = useTranslation();
  const [affiliateCode, setAffiliateCode] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const cartSubTotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      return acc + itemPrice[item.id] * item.quantity;
    }, 0);
  }, [cart]);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
      address: "",
      email: "",
      province: "",
      district: "",
      postal_code: "",
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      phone_number: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only number")
        .max(10, "Phone Number must be 10 digits")
        .required("Phone number is required"),
      address: yup.string().required("Address is required"),
      email: yup.string().email().required("Email is required"),
      province: yup.string().required("Province is required"),
      district: yup.string().required("District is required"),
      postal_code: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only number")
        .required("Postal code is required"),
    }),
  });

  const handleSubmit = async (values) => {
    const response = await fetch(`${PAYMENT_API_BASE_URL}/payments/purchases`, {
      method: "POST",
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        address: values.address,
        phoneNumber: values.phone_number,
        affiliateCode: affiliateCode,
        province: provinces.find(
          (province) => province?.code === values.province
        )?.provinceTh,
        district: districts.find(
          (district) => district?.code === values.district
        )?.districtTh,
        postCode: values.postal_code,
        items: cart.map((item) => ({
          productCode: item.id?.toUpperCase(),
          quantity: item.quantity,
        })),
      }),
    });

    const data = await response.json();

    if (response.status !== 200) {
      toast.error("Failed to submit order");

      if (data.message) {
        toast.error(data.message);
      }

      return;
    }

    if (data.webPaymentUrl) {
      formik.resetForm();
      clearCart();
      window.location = data.webPaymentUrl;
      return;
    }

    toast.success(
      "Facing issue with payment gateway, please contact the support team."
    );
  };

  useEffect(() => {
    const fetchProvinces = async () => {
      const response = await fetch(`${PAYMENT_API_BASE_URL}/provinces`);

      if (response.status !== 200) {
        toast.error("Failed to fetch provinces");
        return;
      }

      const data = await response.json();
      setProvinces(data);
    };

    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      const response = await fetch(
        `${PAYMENT_API_BASE_URL}/provinces/${formik.values.province}/districts`
      );

      if (!response.ok) {
        toast.error("Failed to fetch districts");
        return;
      }

      const data = await response.json();
      setDistricts(data);
    };

    if (formik.values.province) {
      fetchDistricts();
    }
  }, [formik.values.province]);

  return (
    <Layout>
      <div className="container !text-left">
        <div className="row mt-4 pb-3">
          <div className="col-md-12">
            <div className="innerBreadcum">
              <span>{t("Home")}</span> <i class="fa-solid fa-angle-right"></i>{" "}
              <span>{t("AVA")}</span> <i class="fa-solid fa-angle-right"></i>{" "}
              {t("My Cart")}
            </div>
          </div>
        </div>
        <div className="mx-6">
          {cart.length ? (
            <div className="row mt-4 pb-5 shippingBase">
              <div className="col-md-6">
                <h3 className="pb-2 pt-2">{t("My Shipping Address")}</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <span className="pb-1">{t("Name")}</span>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <span className="pb-1">{t("Phone Number")}</span>
                    <input
                      type="text"
                      className="form-control"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone_number &&
                      formik.errors.phone_number && (
                        <div style={{ color: "red" }}>
                          {formik.errors.phone_number}
                        </div>
                      )}
                  </div>
                </div>
                <span className="pb-1">{t("Address")}</span>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address && (
                  <div style={{ color: "red" }}>{formik.errors.address}</div>
                )}

                <div className="row">
                  <div className="col-lg-6 mb-1">
                    <span className="pb-1">{t("Province")}</span>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="province"
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>{t("Open this select menu")}</option>
                      {provinces?.map((province) => (
                        <option value={province.code}>
                          {i18n.language === "th"
                            ? province.provinceTh
                            : province.provinceEn}
                        </option>
                      ))}
                    </select>
                    {formik.touched.province && formik.errors.province && (
                      <div style={{ color: "red" }}>
                        {formik.errors.province}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <span className="pb-1">{t("District")}</span>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="district"
                      value={formik.values.district}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>{t("Open this select menu")}</option>
                      {districts?.map((district) => (
                        <option value={district.code}>
                          {i18n.language === "th"
                            ? district.districtTh
                            : district.districtEn}
                        </option>
                      ))}
                    </select>
                    {formik.touched.district && formik.errors.district && (
                      <div style={{ color: "red" }}>
                        {formik.errors.district}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <span className="pb-1">{t("Postal Code")}</span>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="postal_code"
                      value={formik.values.postal_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value={null}>{t("Open this select menu")}</option>
                      {(
                        districts.find(
                          (district) => district.code === formik.values.district
                        )?.postCodes ?? []
                      ).map((postal_code) => (
                        <option value={postal_code}>{postal_code}</option>
                      ))}
                    </select>
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <div style={{ color: "red" }}>
                          {formik.errors.postal_code}
                        </div>
                      )}
                  </div>
                  <div className="col-lg-6">
                    <span className="pb-1">{t("Email Address")}</span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div style={{ color: "red" }}>{formik.errors.email}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="myCrtbg">
                  <div className="crtTop flex items-center justify-center gap-2 !text-4xl">
                    <img src={cartIcon} alt="cart icon" className="pb-2" />
                    {t("My Cart")}
                  </div>
                  <div className="crtBtm">
                    <h4>{t("Your Order")}</h4>
                    <div className="flex flex-col gap-2 items-center">
                      {cart.map((item) => (
                        <div className="bg-white rounded flex flex-col sm:flex-row p-2 gap-3 ">
                          <div className="flex items-center justify-center">
                            {item.id === "hpv" ? (
                              <img
                                src={img1}
                                alt="HPV Kit"
                                className="w-full sm:w-48 h-36 sm:h-28"
                              />
                            ) : item.id === "stis" ? (
                              <img
                                src={otherproduct}
                                alt="STIs Kit"
                                className="w-full sm:w-48 h-36 sm:h-28"
                              />
                            ) : item.id === "perifit" ? (
                              <img
                                src={perifit}
                                alt="Perifit"
                                className="w-full sm:w-48 h-36 sm:h-28"
                              />
                            ) : (<></>)}
                          </div>

                          <div className="w-full">
                            {item.id === "hpv" ? (
                              <h5 className="text-sm mb-0">
                                {t("HPV Self Collecting Kit")}
                              </h5>
                            ) : item.id === "stis" ? (
                              <h5 className="text-sm mb-0">
                                {t("STIs Self Collecting Kit")}
                              </h5>
                            ) : item.id === "perifit" ? (
                              <h5 className="text-sm mb-0">
                                {t("Vaginal & Pelvic Floor tightening Device")}
                              </h5>
                            ) : (<></>)}

                            <p className="text-xs mb-1">
                              {t(
                                "The AVA Self Collecting Kit allows for at-home screening of cervical cancer, HPV, etc. More marketing text/description x y z."
                              )}
                            </p>
                            <p className="text-xs font-bold">
                              <strong>
                                {new Intl.NumberFormat("th-TH", {
                                  style: "currency",
                                  currency: "THB",
                                  maximumFractionDigits: 0,
                                }).format(itemPrice[item.id])}
                              </strong>
                            </p>
                            <div className="flex gap-2 items-center justify-end text-[#785338] text-sm">
                              {t("Quantity")}
                              <div className="flex items-center">
                                <button
                                  className="border border-r-0 border-[#785338] px-2 py-0.5 rounded-l-md"
                                  onClick={() => addProductToCart(item.id, -1)}
                                >
                                  -
                                </button>
                                <span className="border border-[#785338] min-w-10 text-center px-2.5 py-0.5">
                                  {item.quantity ?? 1}
                                </span>
                                <button
                                  className="border border-l-0 border-[#785338] px-2 py-0.5 rounded-r-md"
                                  onClick={() => addProductToCart(item.id)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("Affiliate Code")}
                          onChange={(e) => setAffiliateCode(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="cartSubtotalbg">
                      <p>
                        {t("Subtotal")}{" "}
                        <span>
                          {new Intl.NumberFormat("th-TH", {
                            style: "currency",
                            currency: "THB",
                            maximumFractionDigits: 0,
                          }).format(cartSubTotal)}
                        </span>
                      </p>
                      <p>
                        {t("Shipping")}{" "}
                        <span>
                          {new Intl.NumberFormat("th-TH", {
                            style: "currency",
                            currency: "THB",
                            maximumFractionDigits: 0,
                          }).format(shippingCharge)}
                        </span>
                      </p>
                    </div>
                    <div className="cartTotal py-4">
                      <strong>{t("Total")}</strong>{" "}
                      <strong>
                        {new Intl.NumberFormat("th-TH", {
                          style: "currency",
                          currency: "THB",
                          maximumFractionDigits: 0,
                        }).format(cartSubTotal + shippingCharge)}
                      </strong>
                    </div>
                    <div className="mycartsubmit pt-2">
                      <button
                        class="commonButton"
                        onClick={formik.handleSubmit}
                      >
                        {t("Confirm My Order")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-span-full mt-4 pb-5 flex flex-col items-center justify-center w-full">
              {" "}
              <h5>{t("Your cart is empty")}</h5>
              <Link className="text-blue-700 hover:text-blue-400" to="/product">
                {t("Click here to go product page")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
