import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const NewsUpdate = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState();

  const handleSignUp = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    if (isValid) {
      toast.success("Successfully submitted");
      setEmail("");
    } else {
      toast.error("Invalid type");
    }
  };

  return (
    <section className="newsBg">
      <div className="container customContainer ">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-10">
          <div>
            <h2>{t("get_news_and_updates")}</h2>
          </div>

          <div className="max-w-96 relative">
            <input
              type="email"
              placeholder={t("enter_email_placeholder")}
              className="rounded-full placeholder:!text-lg p-3 w-96"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <button
              className="absolute right-2 top-2 px-4 py-2 text-white rounded-full"
              style={{ backgroundColor: "rgba(211, 146, 0)" }}
              onClick={handleSignUp}
            >
              {t("signup")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsUpdate;
