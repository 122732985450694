import { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext({});

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [_cart, _setCart] = useState([]);

  useEffect(() => {
    const cart = localStorage.getItem("cart");
    if (cart) {
      _setCart(JSON.parse(cart));
    }
  }, []);

  const setCart = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
    _setCart(data);
  };

  const addProductToCart = (productId, quantity = 1) => {
    const product = _cart.find((p) => p.id === productId);

    if (product?.quantity + quantity < 1) {
      setCart(_cart.filter((p) => p.id !== productId));
      return;
    }

    setCart([
      ..._cart.filter((p) => p.id !== productId),
      { id: productId, quantity: (product?.quantity ?? 0) + quantity },
    ]);
  };

  const updateProductFromCart = (productId, quantity = 1) => {
    setCart([
      ..._cart.filter((p) => p.id !== productId),
      { id: productId, quantity },
    ]);
  };

  const removeProductFromCart = (productId) => {
    setCart([..._cart.filter((p) => p.id !== productId)]);
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{
        cart: _cart,
        addProductToCart,
        updateProductFromCart,
        removeProductFromCart,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
